.card-widget:hover {
  background-color: rgb(239, 239, 113);
  transition: background-color 1s;
}

.card-widget {
  width: 100px;
  height: 100px;
  padding: 2px;
  margin: 3px 6px;
  color: darkgray;
  background-color: white;
  border-radius: 4px;
  box-shadow: -2px 1px 1px #f6fef6, 2px 1px 3px #f6fef6, -1px -1px 1px #f6fef6;

  .card-content {
    height: 10px;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65px;
    .content-top {
      color: rgb(84, 80, 80);
      font-weight: 600;
      font-size: 8pt;
    }
    .content-bottom {
      font-size: 10pt;
      font-weight: 600;
    }
  }
  .card-widget-footer {
    display: flex;
    justify-content: center;
    font-size: 9pt;
    margin-bottom: 2px;
  }
}

.btn-round {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  // background-color: #8eba13;
}
.table-container {
  position: relative;
  margin: 15px;
  display: flex;
  flex-direction: column;
  // width: 90%;
}

.xtable {
  border-radius: 4px;
  thead {
    background-color: white;
    border: 1px solid whitesmoke;
    border-radius: 8px;
    box-shadow: 1px 1px 1px whitesmoke;

    th {
      font-size: 0.5rem;
      color: #9d9d9d;
      font-weight: 600;
      letter-spacing: 0.1rem;
      margin-left: 5px;
      padding: 0 10px !important;
    }
  }
  tbody {
    tr {
      color: #383737;
      font-size: 9pt;
      border-bottom: 1px solid whitesmoke;

      td {
        font-size: 0.6rem;
        font-weight: 300;
        padding-left: 12px;
        color: gray;
      }
    }
  }
}

.xtable-sticky-conatiner {
  table {
    thead, tfoot {
      position: sticky;
    }
    thead{
      top: 0;
    }
    tfoot{
      bottom: 0;
      background-color: white;
    }
  }
}

.xcards-h {
  display: flex;
}
.xcard {
  padding: 5px;
  cursor: pointer;
  font-weight: 700;
  color: #535353;
  .xcard-content {
    padding: 2px 5px;
    min-width: 80px;
    max-width: 160px;
    border: 1px solid #8eba13;
    margin: 1px;
    height: 42px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px whitesmoke;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    transition: all 0.2s ease-out;
    .xcard-content-value {
      font-size: 0.8rem;
    }
    .xcard-content-header {
      font-size: 0.5rem;
      font-weight: 800;
    }
  }
  .xcard-content:hover {
    transform: scale(1.04);
    background-color: #8eba13;
  }
}
