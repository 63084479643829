.navbar-nav {
  flex-direction: row;
}

.nav-link {
  padding-right: .5rem !important;
  padding-left: .5rem !important;
}

/* Fixes dropdown menus placed on the right side */
.ml-auto .dropdown-menu {
  left: auto !important;
  right: 0px;
}



/* Style the top navigation bar */
.navbar {
  display: flex;
  // background-color: rgba($color: #000000, $alpha: 0.6);
}

/* Style the navigation bar links */
.navbar a {
  color: white;
  padding: 14px 20px;
  text-decoration: none;
  //text-align: center;
}

// __<<ngThemingMigrationEscapedComment5>>__
// .navbar a:hover {
//   background-color: #ddd;
//   color: black;
// }

// __<<ngThemingMigrationEscapedComment6>>__
// .row {  
//   display: flex;
//   flex-wrap: wrap;
// }

// __<<ngThemingMigrationEscapedComment7>>__
// __<<ngThemingMigrationEscapedComment8>>__
// .side {
//   flex: 30%;
//   background-color: #f1f1f1;
//   padding: 20px;
// }

// __<<ngThemingMigrationEscapedComment9>>__
// .main {
//   flex: 70%;
//   background-color: white;
//   padding: 20px;
// }

// __<<ngThemingMigrationEscapedComment10>>__
// .fakeimg {
//   background-color: #aaa;
//   width: 100%;
//   padding: 20px;
// }

// __<<ngThemingMigrationEscapedComment11>>__
// .footer {
//   padding: 20px;
//   text-align: center;
//   background: #ddd;
// }

// __<<ngThemingMigrationEscapedComment12>>__
// @media screen and (max-width: 700px) {
//   .row, .navbar {   
//     flex-direction: column;
//   }
// }